import { styled } from '@mui/material/styles';
import {  MenuItem } from '@mui/material';
const getOptions = () => {
    const age = [
        { name: "10代", label: "10代" },
        { name: "20代", label: "20代" },
        { name:"30代", label: "30代" },
        { name: "40代", label: "40代" },
        { name:  "50代", label: "50代" },
        { name: "60代", label: "60代" },
        {name:"70代以上", Label:"70代以上"}
      ];
      const sessions = [
        { name: "1回", label: "1回" },
        { name: "2回", label: "2回" },
        { name: "3回", label: "3回" },
        { name: "4回以上", label: "4回以上" },
      ];
      const gender = [
        { name: "女性", label: "女性" },
        { name: "男性", label: "男性" },
      ];
      
      const self = [
        { name: "匿名", label: "匿名" },
        { name: "自由記入", label: "自由記入" },
        { name: "彼自身", label: "彼自身" },
        { name: "家族", label: "家族" },
      
      ];

      const symptomsList = [
        { value: '普通神経症', label: '普通神経症（不眠、病気不安症、耳鳴り、雑音他）' },
        { value: '不安神経症', label: '不安神経症' },
        { value: 'パニック症', label: 'パニック症（発作含）' },
        { value: '強迫観念・強迫症', label: '強迫観念・強迫症' },
        { value: '対人・社交不安症', label: '対人・社交不安症' },
        { value: '気分障害', label: '気分障害（うつ病、躁鬱病、抑うつ・気分変調症）' },
        { value: 'その他神経症', label: 'その他神経症（離人症、神経症）' },
        { value: 'その他', label: 'その他（発達障害、トラウマ、虐待、他）' }
      ];
     // Styling the selected item and hover
      const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
        '&.Mui-selected': {
        backgroundColor: '#1967d2', // Dark blue background for selected items
        '&:hover': {
        backgroundColor: '#87A2FF', // Darker blue when hovered
        },
      },
    }));

      
      return{ age, sessions,gender,self,symptomsList,StyledMenuItem};
};
export default getOptions;

